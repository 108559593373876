import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import BookVendor from "./BookVendor";
import BookPackage from "./BookPackages";
import RegisterVendor from "./RegisterVendor";
// import ErrorPage from "./ErrorPage";

const router = createBrowserRouter([
  {
    path: "/book/package",
    element: <BookPackage />,
  },
  {
    path: "/book/vendor",
    element: <BookVendor />,
  },
  {
    path: "/register/vendor",
    element: <RegisterVendor />,
  },
  {
    path: "*",
    element: <BookVendor />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
