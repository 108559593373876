import { Form, useFieldAnswer } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import axios from "axios";
import { thankYou } from "./ThankYou";

registerCoreBlocks();

const BookVendor = () => {

  const ratingQuestionAnswer = useFieldAnswer("booking-category");
  // const email = useFieldAnswer("email");
  const firstName = useFieldAnswer("first-name");
  // const lastName = useFieldAnswer("last-name");

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Form
        formId="1"
        formObj={{
          blocks: [
            {
              name: "welcome-screen",
              id: "welcome-screen",
              attributes: {
                buttonText: "Let's Start",
                label:
                  "Greetings And Welcome To <b>F.O.X EVENTS</b> Vendor Booking Platform.",
                description:
                  "Get Ready for Fun Original Experience, It Starts Here! 😃",
                attachment: {
                  type: "image",
                  url: "https://foxtest.sirv.com/Images/socialbig.png",
                },
              },
            },
            {
              name: "date",
              id: "event-date",
              attributes: {
                format: "MMDDYYYY",
                classnames: "first-block",
                required: true,
                label: "Tell Us About Your Event?",
                description: "Let Co-Create Memories 😊",
                separator: "/",
              },
            },
            {
              name: "multiple-choice",
              id: "number-of-people",
              attributes: {
                required: true,
                multiple: false,
                verticalAlign: true,
                label: "How Many People Are You Expecting?",
                description:
                  "We Want To Make Sure We Have Enough Supplies For Everyone 😊",
                choices: [
                  {
                    label: "2-5",
                    value: "2to5",
                  },
                  {
                    label: "5-20",
                    value: "5to10",
                  },
                  {
                    label: "20-50",
                    value: "20to50",
                  },
                  {
                    label: "50-100",
                    value: "50to100",
                  },
                  {
                    label: ">100",
                    value: "100plus",
                  },
                ],
              },
            },
            {
              name: "multiple-choice",
              id: "booking-category",
              attributes: {
                required: true,
                multiple: true,
                verticalAlign: true,
                label: "I Want To Book?",
                description: "Book Your Choice Of Category 😀",
                choices: [
                  {
                    label: "Venue",
                    value: "venue",
                  },
                  {
                    label: "DJ/Band",
                    value: "dj-band",
                  },
                  {
                    label: "Catering Service",
                    value: "catering-service",
                  },
                  {
                    label: "Photographer/Videographer",
                    value: "photo-video",
                  },
                  {
                    label: "Rent Equipment/Décor",
                    value: "decore",
                  },
                ],
              },
            },
            ...(ratingQuestionAnswer?.includes("venue")
              ? [
                  {
                    name: "multiple-choice",
                    id: "venue-type",
                    attributes: {
                      required: true,
                      multiple: true,
                      verticalAlign: true,
                      label: "What Kind of Venue Are You Looking For?",
                      description: "Book Your Choice Of Category 😀",
                      choices: [
                        {
                          label: "Hotel",
                          value: "hotel",
                        },
                        {
                          label: "Open Space",
                          value: "open-space",
                        },
                        {
                          label: "Working Space",
                          value: "working-space",
                        },
                        {
                          label: "Airbnb",
                          value: "airbnb",
                        },
                        {
                          label: "Private/VIP",
                          value: "private-vip",
                        },
                      ],
                    },
                  },
                ]
              : []),
            ...(ratingQuestionAnswer?.includes("dj-band")
              ? [
                  {
                    name: "multiple-choice",
                    id: "dj-band",
                    attributes: {
                      required: true,
                      multiple: true,
                      verticalAlign: true,
                      label: "What Kind of DJ/Band Are You Looking For?",
                      description: "Who Will Be The Life Of The Party? 😃",
                      choices: [
                        {
                          label: "Mobile (private) DJ",
                          value: "mobiledj",
                        },
                        {
                          label: "Genre DJ",
                          value: "genere-dj",
                        },
                        {
                          label: "Club DJ",
                          value: "club-dj",
                        },
                        {
                          label: "Acoustic Live Music",
                          value: "acoustic-live-music",
                        },
                        {
                          label: "Full Band Live Music",
                          value: "band-live-music",
                        },
                      ],
                    },
                  },
                ]
              : []),
            ...(ratingQuestionAnswer?.includes("catering-service")
              ? [
                  {
                    name: "multiple-choice",
                    id: "catering-service",
                    attributes: {
                      required: true,
                      multiple: true,
                      verticalAlign: false,
                      label:
                        "What Kind of Catering Service Are You Looking For?",
                      description: "What Will Be The Taste Of The Party? 😃",
                      choices: [
                        {
                          label: "Breakfast",
                          value: "breakfast",
                        },
                        {
                          label: "Lunch",
                          value: "lunch",
                        },
                        {
                          label: "Dinner",
                          value: " dinner",
                        },
                        {
                          label: "Finger Food",
                          value: "finger-food",
                        },
                        {
                          label: "Water",
                          value: "water",
                        },
                        {
                          label: "Soft Drink",
                          value: "soft-drink",
                        },
                        {
                          label: "Juice",
                          value: "juice",
                        },
                        {
                          label: "Cocktails",
                          value: "cocktails",
                        },
                        {
                          label: "Alcohol",
                          value: "alcohol",
                        },
                        {
                          label: "All",
                          value: "all",
                        },
                      ],
                    },
                  },
                ]
              : []),
            ...(ratingQuestionAnswer?.includes("photo-video")
              ? [
                  {
                    name: "multiple-choice",
                    id: "photo-video",
                    attributes: {
                      required: true,
                      multiple: true,
                      verticalAlign: false,
                      label:
                        "What Kind of Photographer/Videographer Are You Looking For?",
                      description: "Who Will Capture The Best Moments? 😃",
                      choices: [
                        {
                          label: "Informal / Fun",
                          value: "informal-fun",
                        },
                        {
                          label: "Portrait",
                          value: "portrait",
                        },
                        {
                          label: "Product",
                          value: "product",
                        },
                        {
                          label: "Professional",
                          value: "professional",
                        },
                        {
                          label: "Group/Family",
                          value: "group-family",
                        },
                      ],
                    },
                  },
                ]
              : []),
            ...(ratingQuestionAnswer?.includes("decor")
              ? [
                  {
                    name: "multiple-choice",
                    id: "decore",
                    attributes: {
                      required: true,
                      // buttonText: "Next",
                      multiple: true,
                      verticalAlign: false,
                      label:
                        "What Kind of Equipment/Decor Are You Looking For?",
                      description: "Who Will Capture The Best Moments? 😃",
                      choices: [
                        {
                          label: "Tent",
                          value: "tent",
                        },
                        {
                          label: "Table Cloth",
                          value: "table-cloth",
                        },
                        {
                          label: "Stage",
                          value: "stage",
                        },
                        {
                          label: "TV",
                          value: "tv",
                        },
                        {
                          label: "Stage Décor",
                          value: "stage-decore",
                        },
                        {
                          label: "High Chair",
                          value: "high-chair",
                        },
                        {
                          label: "High Table",
                          value: "high-table",
                        },
                        {
                          label: "Chair",
                          value: "chair",
                        },
                        {
                          label: "Full Décor",
                          value: "full-decore",
                        },
                        {
                          label: "Light",
                          value: "light",
                        },
                        {
                          label: "Speaker",
                          value: "speaker",
                        },
                        {
                          label: "Mic",
                          value: "mic",
                        },
                        {
                          label: "Mixer",
                          value: "mixer",
                        },
                        {
                          label: "Table",
                          value: "table",
                        },
                      ],
                    },
                  },
                ]
              : []),
            {
              id: "min-max-group",
              name: "group",
              attributes: {
                label: "What Is Your Budget Range?",
                description: "We are almost Done 😉",
              },
              innerBlocks: [
                {
                  id: "min-budget",
                  name: "number",
                  attributes: {
                    label: "From",
                    setMin: true,
                    // defaultValue: "90000",
                    min: 100,
                    required: true,
                    placeholder: "Your Budget Range Starting From",
                  },
                },
                {
                  id: "max-budget",
                  name: "number",
                  attributes: {
                    label: "To",
                    // defaultValue: "100000",
                    required: true,
                    placeholder: "Your Maximum Budget Range",
                  },
                },
              ],
            },
            {
              id: "contact-group",
              name: "group",
              attributes: {
                label: "Contact Information",
                description: "How Can We Reach You? 😉",
              },
              innerBlocks: [
                {
                  id: "first-name",
                  name: "short-text",
                  attributes: {
                    // defaultValue: "Abubeker",
                    label: "First Name",
                    required: true,
                    placeholder: "Jane",
                  },
                },
                {
                  id: "last-name",
                  name: "short-text",
                  attributes: {
                    // defaultValue: "Shamil",
                    label: "Last Name",
                    required: true,
                    placeholder: "Doe",
                  },
                },
                {
                  id: "email",
                  name: "email",
                  attributes: {
                    label: "Email",
                    // defaultValue: "abedra42@gmail.com",
                    required: true,
                    placeholder: "janedoe@mail.com",
                  },
                },
                {
                  id: "phone-number",
                  name: "number",
                  attributes: {
                    // defaultValue: "0912345678",
                    label: "Phone Number",
                    required: true,
                    placeholder: "09/07xxxxxxxx",
                  },
                },
                {
                  id: "company-name",
                  name: "short-text",
                  attributes: {
                    // defaultValue: "F.O.X EVENTS",
                    label: "Company Name",
                    required: false,
                    placeholder: "If Applicable",
                  },
                },
              ],
            },
            {
              id: "address-group",
              name: "group",
              attributes: {
                label: "Where Are You Located?",
                description: "We Are Excited to Onboard You! 😉",
              },
              innerBlocks: [
                {
                  id: "address",
                  name: "short-text",
                  attributes: {
                    // defaultValue: "Bole, Addis Ababa",
                    label: "Address",
                    required: true,
                    placeholder: "Bole, Addis Ababa",
                  },
                },
                {
                  id: "city",
                  name: "short-text",
                  attributes: {
                    // defaultValue: "Addis Ababa",
                    label: "City",
                    required: true,
                    placeholder: "Addis Ababa",
                  },
                },
                {
                  id: "country",
                  name: "short-text",
                  attributes: {
                    label: "Country",
                    // defaultValue: "Ethiopia",
                    required: true,
                    placeholder: "Ethiopia",
                  },
                },
              ],
            },
          ],
          //       customCSS: `
          //   input: {
          //     border: 100px solid #e3e3e3 !important;
          //     background-image: url('./socialbig.png') !important;
          //   }
          // `,
          settings: {
            animationDirection: "vertical",
            disableWheelSwiping: false,
            // showLettersOnAnswers: false,
            disableNavigationArrows: false,
            disableProgressBar: false,
            // displayBranding: true,
          },
          messages: {
            "label.hintText.key": null,
            "block.defaultThankYouScreen.label": thankYou(firstName),
          },
          theme: {
            font: "Montserrat",
            buttonsBgColor: "#FFC200",
            backgroundImage: "./socialbig.png",
            backgroundImageFocalPoint: {
              x: 45,
              y: 45,
            },
            logo: {
              src: "./Logo.png",
            },
            questionsColor: "#fff",
            answersColor: "#fff",
            buttonsFontColor: "#fff",
            buttonsBorderRadius: 6,
            // typographyPreset: 'sm',
            // buttonsBorderWidth: 5,
            // buttonsBorderColor: "#Fc2", //green
            errorsFontColor: "#fff",
            errorsBgColor: "#f00",
            backgroundColor: "#CE9C01",
            progressBarFillColor: "#000",
            progressBarBgColor: "#ccc",
          },
        }}
        beforeGoingNext={({
          setIsFieldValid,
          setIsPending,
          currentBlockId,
          answers,
          setFieldValidationErr,
          setIsCurrentBlockSafeToSwipe,
          goToField,
          goToBlock,
          goNext,
        }) => {
          if (
            currentBlockId === "min-max-group" &&
            answers["max-budget"].value < answers["min-budget"].value
          ) {
            // setIsPending(true)
            console.log("The Value in Max is: ", goToField);
            setIsFieldValid("max-budget", false);
            setFieldValidationErr(
              "max-budget",
              "Maximum Budget Must Be Greater Than Minimum Budget"
            );
            setIsCurrentBlockSafeToSwipe(false);
            goToBlock("min-max-group");
            // setIsPending(false)
          } else if (
            currentBlockId === "event-date" &&
            // check if the date is not in the past and needs to be 2 days ahead
            new Date(answers["event-date"].value) <
              new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000)
          ) {
            // console.log("The Date: ", new Date(answers["event-date"].value) , " : " , new Date(new Date().getTime() + 1* 24 * 60 * 60 * 1000));
            setIsFieldValid("event-date", false);
            setFieldValidationErr(
              "event-date",
              "Enter a Valid Date! The Date Should be at Least 2 Days Ahead."
            );
            setIsCurrentBlockSafeToSwipe(false);
            // goToBlock("min-max-group");
          } else {
            setIsFieldValid(currentBlockId, true);
            setFieldValidationErr(currentBlockId, "");
            setIsCurrentBlockSafeToSwipe(true);
            goNext();
          }
        }}
        onSubmit={async (
          answers,
          { completeForm, setIsSubmitting, goToBlock, setSubmissionErr }
        ) => {
          // console.log("The Data On Submit Form: ", answers.answers);

          //todo clean the data from the answers.answers object
          const cleanedData = Object.entries(answers.answers).reduce(
            (acc, [key, valueObj]) => {
              acc[key] = valueObj.value;
              return acc;
            },
            {}
          );

          // console.log("The Cleaned Data: ", cleanedData);
          //todo send the data to the server using axios with try catch block
          try {
             await axios.post(
              "https://vast-tan-calf-toga.cyclic.app/forms/book/vendor",
              // "http://localhost:3001/forms/book/vendor",
              cleanedData,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            // console.log("The Response: ", response);

            setIsSubmitting(false);
            completeForm();
          } catch (error) {
            console.log("The Error: ", error);
            setSubmissionErr("There Was An Error When Submitting The Form! Please Try Again.");
          }
        }}
      />
    </div>
  );
};

export default BookVendor;
