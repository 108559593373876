import { Form, useFieldAnswer } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import axios from "axios";
import { thankYou } from "./ThankYou";
import {isMobile} from 'react-device-detect';

registerCoreBlocks();

const ProductLaunch = () => {

  const name = useFieldAnswer("name");

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Form
        formId="1"
        formObj={{
          blocks: [
            {
              name: "welcome-screen",
              id: "welcome-screen",
              attributes: {
                buttonText: "Let's Start",
                label:
                  "Greetings And Welcome To <b>F.O.X EVENTS</b> Package Booking Form.",
                description:
                  "Get Ready for Fun Original Experience, It Starts Here! 😃",
                attachment: {
                  type: "image",
                  url: "https://foxtest.sirv.com/Images/bussinessbig.png",
                },
              },
            },
            {
              id: "contact-group",
              name: "group",
              attributes: {
                label: "Contact Information",
                description: "How Can We Reach You? 😉",
              },
              innerBlocks: [
                {
                  id: "name",
                  name: "short-text",
                  attributes: {
                    // defaultValue: "Abubeker",
                    label: "Name",
                    required: true,
                    placeholder: "Jane Doe / Company Inc",
                  },
                },
                {
                  id: "phone-number",
                  name: "number",
                  attributes: {
                    // defaultValue: "0912345678",
                    label: "Phone Number",
                    required: true,
                    placeholder: "09/07xxxxxxxx",
                  },
                },
                {
                  id: "email",
                  name: "email",
                  attributes: {
                    label: "Email",
                    // defaultValue: "abedra42@gmail.com",
                    required: true,
                    placeholder: "janedoe@mail.com",
                  },
                },
              ],
            },
            {
              id: "package-type",
              name: "multiple-choice",
              attributes: {
                verticalAlign: true,
                label: "What Kind of Package Are You Interested In?",
                description: "We Are Excited to Prepare Your Special Package! 😊",
                choices: [{
                    label: "Special Holiday Package",
                    value: "special-holiday",
                },{
                    label: "Product Launch",
                    value: "product-launch",
                },{
                    label: "Birthday Package",
                    value: "birthday",
                }],
                required: true,
                // placeholder: "Bole, Addis Ababa",
              },
            },
            {
              id: "comment",
              name: "short-text",
              attributes: {
                // defaultValue: "No comment",
                label: "Questions or Feedback?",
                description: "Feel free to ask us anything or comment 😉",
                required: false,
                placeholder: "Feels Amazing To Be Here!",
              },
            },
          ],
          settings: {
            animationDirection: "vertical",
            disableWheelSwiping: isMobile,
            // showLettersOnAnswers: false,
            disableNavigationArrows: false,
            disableProgressBar: isMobile, 
            // displayBranding: true,
          },
          messages: {
            "label.hintText.key": null,
            "block.defaultThankYouScreen.label": thankYou(name),
          },
          theme: {
            font: "Montserrat",
            buttonsBgColor: "#FFC200",
            backgroundImage: "./socialbig.png",
            backgroundImageFocalPoint: {
              x: 45,
              y: 45,
            },
            logo: {
              src: "./Logo.png",
            },
            questionsColor: "#fff",
            answersColor: "#fff",
            buttonsFontColor: "#fff",
            buttonsBorderRadius: 6,
            // typographyPreset: 'sm',
            // buttonsBorderWidth: 5,
            // buttonsBorderColor: "#Fc2", //green
            errorsFontColor: "#fff",
            errorsBgColor: "#f00",
            backgroundColor: "#CE9C01",
            progressBarFillColor: "#000",
            progressBarBgColor: "#ccc",
          },
        }}
        onSubmit={async (
          answers,
          { completeForm, setIsSubmitting, goToBlock, setSubmissionErr }
        ) => {
          const cleanedData = Object.entries(answers.answers).reduce(
            (acc, [key, valueObj]) => {
              acc[key] = valueObj.value;
              return acc;
            },
            {}
          );

          console.log("The Cleaned Data in Product Launch: ", cleanedData);
          //todo send the data to the server using axios with try catch block
          try {
             const response = await axios.post(
              "https://vast-tan-calf-toga.cyclic.app/forms/book/package",
              // "http://localhost:3001/forms/book/package",
              cleanedData,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            console.log("The Response: ", response);

            setIsSubmitting(false);
            completeForm();
          } catch (error) {
            console.log("The Error: ", error);
            setSubmissionErr("There Was An Error When Submitting The Form! Please Try Again." + error);
          }
        }}
      />
    </div>
  );
};

export default ProductLaunch;
