import { Form, useFieldAnswer } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import axios from "axios";
import { thankYou } from "./ThankYou";

registerCoreBlocks();

const RegisterVendor = () => {
  const businessTypeQuestionAnswer = useFieldAnswer("business-type");
  const serviceTypeQuestionAnswer = useFieldAnswer("service-type");
  // const email = useFieldAnswer("email");
  const firstName = useFieldAnswer("name");
  // const lastName = useFieldAnswer("last-name");

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Form
        formId="1"
        formObj={{
          blocks: [
            {
              name: "welcome-screen",
              id: "welcome-screen",
              attributes: {
                buttonText: "Let's Start",
                label:
                  "Greetings And Welcome To <b>F.O.X EVENTS</b> Vendor Registration Form.",
                description:
                  "Get Ready for Fun Original Experience, It Starts Here! 😃",
                attachment: {
                  type: "image",
                  url: "https://foxtest.sirv.com/Images/communitybig.png",
                },
              },
            },
            {
              id: "contact-info-company-group",
              name: "group",
              attributes: {
                label: "Information About Your Business",
                description: "Tell Us About Your Business 😉",
              },
              innerBlocks: [
                {
                  id: "name",
                  name: "short-text",
                  attributes: {
                    // defaultValue: "Abubeker",
                    label: "Name",
                    required: true,
                    placeholder: "Jane Doe / Company Inc",
                  },
                },
                {
                  id: "phone-number",
                  name: "number",
                  attributes: {
                    // defaultValue: "0912345678",
                    label: "Phone Number",
                    required: true,
                    placeholder: "09/07xxxxxxxx",
                  },
                },
                {
                  id: "email",
                  name: "email",
                  attributes: {
                    label: "Email",
                    // defaultValue: "abedra42@gmail.com",
                    required: true,
                    placeholder: "janedoe@mail.com",
                  },
                },
              ],
            },
            {
              id: "contact-info-person-group",
              name: "group",
              attributes: {
                label: "Contact Person Information",
                description: "Someone We Can Reach Out To 😉",
              },
              innerBlocks: [
                {
                  id: "contact-name",
                  name: "short-text",
                  attributes: {
                    // defaultValue: "Abubeker",
                    label: "Name",
                    required: true,
                    placeholder: "Jane Doe / Company Inc",
                  },
                },
                {
                  id: "contact-phone-number",
                  name: "number",
                  attributes: {
                    // defaultValue: "0912345678",
                    label: "Phone Number",
                    required: true,
                    placeholder: "09/07xxxxxxxx",
                  },
                },
                {
                  id: "contact-email",
                  name: "email",
                  attributes: {
                    label: "Email",
                    // defaultValue: "abedra42@gmail.com",
                    required: true,
                    placeholder: "janedoe@mail.com",
                  },
                },
              ],
            },
            {
              id: "service-type",
              name: "multiple-choice",
              attributes: {
                verticalAlign: true,
                // defaultValue: "Bole, Addis Ababa",
                label: "Which industry of business do you engage in?",
                description: "We are excited to know more about you 😉",
                choices: [
                  {
                    label: "Photographer / Videographer",
                    value: "photo-video",
                  },
                  {
                    label: "DJ / Band",
                    value: "dj-band",
                  },
                  {
                    label: "Venue",
                    value: "venue",
                  },
                  {
                    label: "Catering",
                    value: "catering",
                  },
                  {
                    label: "Equipment Rental / Décor",
                    value: "decore",
                  },
                  {
                    label: "Printing and Related Services",
                    value: "printing",
                  },
                  {
                    label: "Ushering",
                    value: "ushering",
                  },
                  {
                    label: "Host",
                    value: "host",
                  },
                  {
                    label: "Other Services",
                    value: "other-services",
                  },
                ],
                required: true,
                // placeholder: "Bole, Addis Ababa",
              },
            },
            ...(serviceTypeQuestionAnswer?.includes("other-services")
              ? [
                  {
                    id: "other-service",
                    name: "short-text",
                    attributes: {
                      label: "Please Specify Your Service Type",
                      description: "So we can better showcase your business. 😉",
                      setMin: true,
                      // defaultValue: "90000",
                      min: 100,
                      required: true,
                      placeholder: "Your Service Type",
                    },
                  },
                ]
              : []),
            {
              id: "business-type",
              name: "multiple-choice",
              attributes: {
                label: "Are you registered or a freelancer?",
                description: "Some Information About Your Business 😉",
                choices: [
                  {
                    label: "Registered",
                    value: "registered",
                  },
                  {
                    label: "Freelancer",
                    value: "freelancer",
                  },
                ],
                required: true,
              },
            },
            ...(businessTypeQuestionAnswer?.includes("registered")
              ? [
                  {
                    id: "tin",
                    name: "number",
                    attributes: {
                      label: "Your TIN Number",
                      description: "So we can verify your business. 😉",
                      setMin: true,
                      // defaultValue: "90000",
                      min: 100,
                      required: true,
                      placeholder: "Your Tax Identification Number",
                    },
                  },
                ]
              : []),
            {
              id: "vendor-price-group",
              name: "group",
              attributes: {
                label: "What Is Your Price Range?",
                description: "We are almost Done 😉",
              },
              innerBlocks: [
                {
                  id: "min-price",
                  name: "number",
                  attributes: {
                    label: "From",
                    setMin: true,
                    // defaultValue: "90000",
                    min: 100,
                    required: true,
                    placeholder: "Your Service Price Starting From",
                  },
                },
                {
                  id: "max-price",
                  name: "number",
                  attributes: {
                    label: "To",
                    // defaultValue: "100000",
                    required: true,
                    placeholder: "Your Maximum Service Price",
                  },
                },
              ],
            },
            {
              name: "multiple-choice",
              id: "years-in-business",
              attributes: {
                required: true,
                multiple: false,
                verticalAlign: true,
                label: "How many years have you been in the business for?",
                description: "Your experience in the business",
                choices: [
                  {
                    label: "Less Than 1 Year",
                    value: "0to1",
                  },
                  {
                    label: "1 to 3 Years",
                    value: "1to3",
                  },
                  {
                    label: "3 to 5 Years",
                    value: "3to5",
                  },
                  {
                    label: "More Than 5 Years",
                    value: "5plus",
                  },
                ],
              },
            },
            {
              id: "address-group",
              name: "group",
              attributes: {
                label: "Where Are You Located?",
                description: "We Are Excited to Onboard You! 😉",
              },
              innerBlocks: [
                {
                  id: "address",
                  name: "short-text",
                  attributes: {
                    // defaultValue: "Bole, Addis Ababa",
                    label: "Address",
                    required: true,
                    placeholder: "Bole, Addis Ababa",
                  },
                },
                {
                  id: "city",
                  name: "short-text",
                  attributes: {
                    // defaultValue: "Addis Ababa",
                    label: "City",
                    required: true,
                    placeholder: "Addis Ababa",
                  },
                },
                {
                  id: "country",
                  name: "short-text",
                  attributes: {
                    label: "Country",
                    // defaultValue: "Ethiopia",
                    required: true,
                    placeholder: "Ethiopia",
                  },
                },
              ],
            },
            {
              id: "comment",
              name: "short-text",
              attributes: {
                // defaultValue: "No comment",
                label: "Questions or Feedback?",
                description: "Feel free to ask us anything or comment 😉",
                required: false,
                placeholder: "Feels Amazing To Be Here!",
              },
            },
          ],
          settings: {
            animationDirection: "vertical",
            disableWheelSwiping: false,
            // showLettersOnAnswers: false,
            disableNavigationArrows: false,
            disableProgressBar: false,
            // displayBranding: true,
          },
          messages: {
            "label.hintText.key": "",
            "block.defaultThankYouScreen.label": thankYou(firstName),
          },
          theme: {
            font: "Montserrat",
            buttonsBgColor: "#FFC200",
            backgroundImage: "./socialbig.png",
            backgroundImageFocalPoint: {
              x: 45,
              y: 45,
            },
            logo: {
              src: "./Logo.png",
            },
            questionsColor: "#fff",
            answersColor: "#fff",
            buttonsFontColor: "#fff",
            buttonsBorderRadius: 6,
            // typographyPreset: 'sm',
            // buttonsBorderWidth: 5,
            // buttonsBorderColor: "#Fc2", //green
            errorsFontColor: "#fff",
            errorsBgColor: "#f00",
            backgroundColor: "#CE9C01",
            progressBarFillColor: "#000",
            progressBarBgColor: "#ccc",
          },
        }}
        onSubmit={async (
          answers,
          { completeForm, setIsSubmitting, goToBlock, setSubmissionErr }
        ) => {
          // console.log("The Data On Submit Form: ", answers.answers);

          //todo clean the data from the answers.answers object
          const cleanedData = Object.entries(answers.answers).reduce(
            (acc, [key, valueObj]) => {
              acc[key] = valueObj.value;
              return acc;
            },
            {}
          );

          console.log("The Cleaned Data: ", cleanedData);
          //todo send the data to the server using axios with try catch block
          try {
            const response = await axios.post(
              "https://vast-tan-calf-toga.cyclic.app/forms/register/vendor",
              // "http://localhost:3001/forms/register/vendor",
              cleanedData,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            console.log("The Response: ", response);

            setIsSubmitting(false);
            completeForm();
          } catch (error) {
            console.log("The Error: ", error);
            setSubmissionErr(
              "There Was An Error When Submitting The Form! Please Try Again."
            );
          }
        }}
      />
    </div>
  );
};

export default RegisterVendor;
